export const getAverageRgb = async (src: string): Promise<Uint8ClampedArray> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      reject('Failed to get canvas context');
      return;
    }

    const img = new Image();
    img.setAttribute('crossOrigin', '');
    img.onload = () => {
      context.drawImage(img, 0, 0, 1, 1);
      const imageData = context.getImageData(0, 0, 1, 1).data;
      resolve(imageData.slice(0, 3));
    };
    img.onerror = () => reject('Failed to load image');
    img.src = src;
  });
};
