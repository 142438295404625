import { SubDiscountPriceModel } from '@/constants/ProductConfig';

export function formatPrice(price: number | null | undefined): string {
  if (!price) return '0.00';
  return price.toFixed(2);
}

export function getDiscountAmount(product: SubDiscountPriceModel | null | undefined): number {
  if (!product) return 0;
  return product.standard.price - product.discount.price;
}

export function getDiscountPercentage(product: SubDiscountPriceModel | null | undefined): number {
  if (!product) return 0;
  return 1 - product.discount.price / product.standard.price;
}

export function formatDiscountPercentage(product: SubDiscountPriceModel | null | undefined): string {
  const percentage = getDiscountPercentage(product);
  return (percentage * 100).toFixed(0) + '%';
}
