export const PromoteFlowStepFormatter = (product: string, step: number, isShort?: boolean) => {
  if (product === 'playlisting') {
    switch (step) {
      case 1:
        return 'Choose Songs';
      case 2:
        return 'Choose Playlists';
      case 3:
        return 'Summary';
      case 4:
        return 'Payment';
      default:
        return step.toString();
    }
  }
  if (product === 'fan-hub') {
    switch (step) {
      case 0:
        return 'Walkthrough';
      case 1:
        return 'Landing';
      case 2:
        return 'Community';
      case 3:
        return 'Spotlight Release';
      case 4:
        return 'YouTube Video';
      case 5:
        return 'Platform Links';
      case 6:
        return 'Tracking Pixels';
      case 7:
        return 'Choose URL path';
      case 8:
        return 'Background Picker';
      case 9:
        return 'Website Preview';
      case 10:
        return 'Summary';
      case 11:
        return 'View Website';
      default:
        return step.toString();
    }
  }
  if (product === 'meta' || product === 'tiktok') {
    switch (step) {
      case 1:
        return isShort ? 'Platform' : 'Goal';
      case 2:
        return isShort ? 'Budget' : 'Platform';
      case 3:
        return isShort ? 'Audience' : 'Budget';
      case 4:
        return isShort ? 'Design your ad' : 'Audience';
      case 5:
        return isShort ? 'Summary' : 'Design your ad';
      case 6:
        return 'Summary';
      default:
        return step.toString();
    }
  }
  return '';
};
