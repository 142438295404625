import { InstagramAudienceMetricsModel, MetricsPlaylistsModel, YoutubeAudienceMetricsModel } from '@/models/Metrics';
import { SpotifyArtistModel, SpotifySearchDataItemsModel } from '@/models/Spotify';

import {
  SubscriptionArtistsMock,
  SubscriptionMetricsMock,
  SubscriptionPlaylistsMock,
  SubscriptionTracksMock,
} from '../mocks/SubscriptionMocks';

export const SubscriptionInstagramDataFormatter = (data: InstagramAudienceMetricsModel) => {
  const dummyData = SubscriptionMetricsMock;
  const formattedData = {
    topCountries:
      data.topCountries.length > 0
        ? [
            data.topCountries[0],
            ...dummyData.instagramTopCountries.map((country) => ({
              name: country.name,
              code: country.code,
              percent: country.percent,
              followers: country.followers,
            })),
          ]
        : dummyData.instagramTopCountries,
    topCities:
      data.topCities.length > 0
        ? [
            data.topCities[0],
            ...dummyData.instagramTopCities.map((city) => ({
              name: city.name,
              country: city.country,
              percent: city.percent,
              followers: city.followers,
            })),
          ]
        : dummyData.instagramTopCities,
    gendersPerAgeGroup: dummyData.gendersPerAgeGroup,
    genders: dummyData.genders,
    brandAffinities:
      data.brandAffinities.length > 0
        ? [
            data.brandAffinities[0],
            ...dummyData.brandAffinities.map((brand) => ({
              name: brand.name,
              weight: brand.weight,
              affinity: brand.affinity,
            })),
          ]
        : dummyData.brandAffinities,
    interestAffinities:
      data.interestAffinities.length > 0
        ? [
            data.interestAffinities[0],
            ...dummyData.interestAffinities.map((interest) => ({
              name: interest.name,
              weight: interest.weight,
              affinity: interest.affinity,
            })),
          ]
        : dummyData.interestAffinities,
    notableFollowers:
      data.notableFollowers.length > 0
        ? [
            data.notableFollowers[0],
            ...dummyData.notableFollowers.map((follower) => ({
              fullName: follower.fullName,
              followers: follower.followers,
              picture: follower.picture,
            })),
          ]
        : dummyData.notableFollowers,
  };
  return formattedData;
};

export const SubscriptionYoutubeDataFormatter = (data: YoutubeAudienceMetricsModel) => {
  const dummyData = SubscriptionMetricsMock;
  const formattedData = {
    gendersPerAgeGroup: dummyData.gendersPerAgeGroup,
    genders: dummyData.genders,
    avgCommentsPerPost: 0,
    avgLikesPerPost: 0,
    engagementRate: 0,
    subscribers: 0,
    notableSubscribers:
      data.notableSubscribers.length > 0
        ? [
            data.notableSubscribers[0],
            ...dummyData.notableSubscribers.map((follower) => ({
              fullName: follower.fullName,
              subscribers: follower.subscribers,
              picture: follower.picture,
            })),
          ]
        : dummyData.notableSubscribers,
  };
  return formattedData;
};

export const SubscriptionPlaylistDataFormatter = (data: MetricsPlaylistsModel[]) => {
  const dummyData = SubscriptionPlaylistsMock;
  const formattedData = [data[0], ...dummyData];
  return formattedData;
};

export const SubscriptionArtistDataFormatter = (data: SpotifyArtistModel[]) => {
  const dummyData = SubscriptionArtistsMock;
  const formattedData = [data[0], ...dummyData];
  return formattedData;
};

export const SubscriptionTracksDataFormatter = (data: SpotifySearchDataItemsModel[]) => {
  const dummyData = SubscriptionTracksMock;
  const formattedData = [data[0], ...dummyData];
  return formattedData;
};
