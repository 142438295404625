const numberFormatter = (num: number, decimals?: number) => {
  if (Math.abs(num) > 999 && Math.abs(num) < 999999) {
    return (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(decimals || 1) + 'k';
  }

  if (Math.abs(num) > 999999 && Math.abs(num) < 999999999) {
    return (Math.sign(num) * (Math.abs(num) / 1000000)).toFixed(decimals || 1) + 'M';
  }

  if (Math.abs(num) > 999999999) {
    return (Math.sign(num) * (Math.abs(num) / 1000000000)).toFixed(decimals || 1) + 'B';
  }

  return (Math.sign(num) * Math.abs(num)).toFixed(decimals || 0);
};

export default numberFormatter;
